/* Remove spinner for all number inputs */
input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  appearance: none; /* Standard */
}

/* Optionally add this to prevent spinners in hover states */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-label {
  margin-bottom: 2px;
}

.separator {
  width: 1px;
  height: 20px;
  background-color: #ccc;
  margin: 0 10px;
}
