[class*='col-md-'] {
  padding-right: 0 !important;
}

.require {
  color: red;
  font-weight: bold;
}

.form-label {
  font-weight: 600;
}
.form-control {
  width: 100% !important;
}

.close-btn {
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}
